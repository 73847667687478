import * as t from "io-ts";
import { UserProfileEnumV } from "@type/core/user/profile.types";
import { UserStatusEnumV } from "@type/core/user/status.types";

export const userDetailsResponseDtoDecoderContent = {
  id: t.string,
  firstName: t.string,
  lastName: t.string,
  email: t.string,
  profiles: t.array(UserProfileEnumV),
  status: UserStatusEnumV,
  avatarResourceId: t.union([t.string, t.null]),
  phoneNumber: t.union([t.string, t.null]),
  ordinalNumber: t.union([t.string, t.null]),
};

export const userDetailsResponseDtoDecoder = t.type(
  userDetailsResponseDtoDecoderContent,
);

export type UserDetailsResponseDto = t.TypeOf<
  typeof userDetailsResponseDtoDecoder
>;
