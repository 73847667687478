import "promise-polyfill/src/polyfill";
import "unfetch/polyfill";
import "abortcontroller-polyfill";
import {
  ParsedResponse,
  ServerResponseParser,
} from "@utils/rest/ServerResponseParse";
import { logout } from "@state/auth/AuthEvents";
import logger from "@utils/logger";
import { DEFAULT_500_ERROR } from "@utils/Constant";
import { SorterResult } from "antd/es/table/interface";
import { Pageable } from "@type/core/list/pagination.types";
import * as t from "io-ts";
import { DecoderTypeComputer } from "./ServerResponseValidator";
import configuration from "@utils/Config";

type RestMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

type SearchDto = Record<string, unknown>;

const executeCall = <
  U,
  PrimaryType extends t.Props | t.Mixed = t.Props,
  DecoderType extends
    DecoderTypeComputer<PrimaryType> = DecoderTypeComputer<PrimaryType>,
>(
  fetchPromise: Promise<Response>,
  decoder?: DecoderType,
) => {
  return fetchPromise
    .then((response: Response) => {
      logger.debug(`HTTP response ${response.status.toString()}`);
      if (response.status === 401) {
        logout();
      }
      if (response.status === 500) {
        return DEFAULT_500_ERROR;
      }
      return new ServerResponseParser<U>().parseResponse<
        PrimaryType,
        DecoderType
      >(response, decoder);
    })
    .catch(() => {
      return DEFAULT_500_ERROR;
    });
};

const removeTrailingSlash = (url: string): string => {
  return url.endsWith("/") ? url.substring(0, url.length - 1) : url;
};

const cleanUrl = (url: string): string => {
  const items = url.split("?");
  if (items.length === 1) {
    return removeTrailingSlash(items[0]);
  }

  const u = items[0];
  const q = items[1];

  return `${removeTrailingSlash(u)}?${q}`;
};

export const makeRestCall = <
  T,
  U = undefined,
  PrimaryType extends t.Props | t.Mixed = t.Props,
  DecoderType extends
    DecoderTypeComputer<PrimaryType> = DecoderTypeComputer<PrimaryType>,
>(
  url: string,
  method: RestMethod,
  data?: T,
  decoder?: DecoderType,
): Promise<ParsedResponse<U>> => {
  const controller = new AbortController();
  const signal = controller.signal;
  const cleanedUrl = cleanUrl(url);

  setTimeout(() => {
    controller.abort();
  }, 15000);

  const headers: HeadersInit = {
    ["Content-Type"]: "application/json",
    ["x-api-key"]: configuration.xApiKey,
  };

  const fetchPromise = fetch(cleanedUrl, {
    method,
    signal,
    credentials: "include",
    body: data ? JSON.stringify(data) : undefined,
    headers,
  });
  return executeCall<U, PrimaryType, DecoderType>(fetchPromise, decoder);
};

export const makeRestMultipartCall = <
  U,
  PrimaryType extends t.Props | t.Mixed = t.Props,
  DecoderType extends
    DecoderTypeComputer<PrimaryType> = DecoderTypeComputer<PrimaryType>,
>(
  url: string,
  method: RestMethod,
  data: FormData,
  decoder?: DecoderType,
): Promise<ParsedResponse<U>> => {
  const controller = new AbortController();
  const signal = controller.signal;
  const cleanedUrl = cleanUrl(url);

  const headers: HeadersInit = {
    ["x-api-key"]: configuration.xApiKey,
  };

  setTimeout(() => {
    controller.abort();
  }, 15000);
  const fetchPromise = fetch(cleanedUrl, {
    method,
    signal,
    credentials: "include",
    body: data,
    headers,
  });
  return executeCall<U, PrimaryType>(fetchPromise, decoder);
};

const computeQueryParamPrefix = (index: number): string => {
  return index !== 0 ? "&" : "";
};

const buildSearchParameters = <T extends SearchDto>(dto?: T): string => {
  return dto
    ? Object.keys(dto)
        .map((key, index) =>
          dto[key] !== undefined
            ? `${computeQueryParamPrefix(index)}${key}=${String(dto[key])}`
            : "",
        )
        .join("")
    : "";
};

export const restListHandlerPagination = <
  T,
  U extends SearchDto = Record<string, unknown>,
  PrimaryType extends t.Props = t.Props,
  DecoderType extends
    DecoderTypeComputer<PrimaryType> = DecoderTypeComputer<PrimaryType>,
>(
  endpointUrl: string,
  decoder?: DecoderType,
): ((data: {
  page: number;
  limit: number;
  sorter?: SorterResult<T> | SorterResult<T>[];
  dto?: U;
}) => Promise<ParsedResponse<Pageable<T>>>) => {
  return ({
    page,
    limit,
    sorter,
    dto,
  }: {
    page: number;
    limit: number;
    sorter?: SorterResult<T> | SorterResult<T>[];
    dto?: U;
  }) => {
    const searchParameters = buildSearchParameters<U>(dto);
    let order;
    switch (Array.isArray(sorter) ? sorter[0].order : sorter?.order) {
      case "ascend":
        order = ",ASC";
        break;
      case "descend":
        order = ",DESC";
        break;
      default:
        order = "";
        break;
    }
    return makeRestCall<unknown, Pageable<T>, PrimaryType, DecoderType>(
      `${endpointUrl}?page=${page.toString()}&size=${limit.toString()}&sort=${(order && (Array.isArray(sorter) ? sorter[0].columnKey : sorter?.columnKey)) ?? ""}${order}&${searchParameters}`,
      "GET",
      undefined,
      decoder,
    );
  };
};

export const restListHandler = <
  T,
  U extends SearchDto = Record<string, unknown>,
  PrimaryType extends t.Mixed = t.Mixed,
  DecoderType extends
    DecoderTypeComputer<PrimaryType> = DecoderTypeComputer<PrimaryType>,
>(
  endpointUrl: string,
  suffixUrl?: string,
  decoder?: DecoderType,
): ((data: {
  sorter?: SorterResult<T> | SorterResult<T>[];
  dto?: U;
  id?: string;
}) => Promise<ParsedResponse<T[]>>) => {
  return ({
    sorter,
    dto,
    id,
  }: {
    sorter?: SorterResult<T> | SorterResult<T>[];
    dto?: U;
    id?: string;
  }) => {
    const searchParameters = buildSearchParameters<U>(dto);
    let order;
    switch (Array.isArray(sorter) ? sorter[0].order : sorter?.order) {
      case "ascend":
        order = ",ASC";
        break;
      case "descend":
        order = ",DESC";
        break;
      default:
        order = "";
        break;
    }
    return makeRestCall<unknown, T[], PrimaryType, DecoderType>(
      `${endpointUrl}${id ?? ""}${suffixUrl ?? ""}?&sort=${(order && (Array.isArray(sorter) ? sorter[0].columnKey : sorter?.columnKey)) ?? ""}${order}&${searchParameters}`,
      "GET",
      undefined,
      decoder,
    );
  };
};

export const restGetHandler = <
  U,
  T extends SearchDto = Record<string, unknown>,
  PrimaryType extends t.Props = t.Props,
  DecoderType extends
    DecoderTypeComputer<PrimaryType> = DecoderTypeComputer<PrimaryType>,
>(
  endpointUrl: string,
  decoder?: DecoderType,
): ((data: { dto?: T }) => Promise<ParsedResponse<U>>) => {
  return ({ dto }: { dto?: T }) => {
    const searchParameters = buildSearchParameters<T>(dto);
    return makeRestCall<unknown, U, PrimaryType, DecoderType>(
      `${endpointUrl}?${searchParameters}`,
      "GET",
      undefined,
      decoder,
    );
  };
};

export const restDetailsHandler = <
  T,
  PrimaryType extends t.Props = t.Props,
  DecoderType extends
    DecoderTypeComputer<PrimaryType> = DecoderTypeComputer<PrimaryType>,
>(
  endpointUrl: string,
  suffixUrl?: string,
  decoder?: DecoderType,
): ((data: {
  id: string;
  upperEntityId?: string;
}) => Promise<ParsedResponse<T>>) => {
  return (data: { id: string; upperEntityId?: string }) =>
    makeRestCall<unknown, T, PrimaryType, DecoderType>(
      endpointUrl +
        (data.upperEntityId ?? data.id) +
        (suffixUrl ?? "") +
        (data.upperEntityId ? data.id : ""),
      "GET",
      undefined,
      decoder,
    );
};

export const restGetUniqueHandler = <
  T,
  U extends SearchDto = Record<string, unknown>,
  PrimaryType extends t.Props = t.Props,
  DecoderType extends
    DecoderTypeComputer<PrimaryType> = DecoderTypeComputer<PrimaryType>,
>(
  endpointUrl: string,
  suffixUrl?: string,
  decoder?: DecoderType,
): ((data: { dto?: U }) => Promise<ParsedResponse<T>>) => {
  return ({ dto }: { dto?: U }) => {
    const searchParameters = buildSearchParameters<U>(dto);
    return makeRestCall<unknown, T>(
      `${endpointUrl + (suffixUrl ?? "")}?${searchParameters}`,
      "GET",
      undefined,
      decoder,
    );
  };
};

export const restIdListHandler = <T>(
  endpointUrl: string,
): ((id: string) => Promise<ParsedResponse<T[]>>) => {
  return (id: string) => makeRestCall<unknown, T[]>(endpointUrl + id, "GET");
};

export const restCreationHandler = <
  T,
  U,
  PrimaryType extends t.Props = t.Props,
  DecoderType extends
    DecoderTypeComputer<PrimaryType> = DecoderTypeComputer<PrimaryType>,
>(
  endpointUrl: string,
  suffixUrl?: string,
  suffix2Url?: string,
  decoder?: DecoderType,
): ((data: {
  upperEntityId?: string;
  upperEntity2Id?: string;
  dto: T;
}) => Promise<ParsedResponse<U>>) => {
  return (data: { upperEntityId?: string; upperEntity2Id?: string; dto: T }) =>
    makeRestCall<T, U>(
      endpointUrl +
        (data.upperEntityId ?? "") +
        (suffixUrl ?? "") +
        (data.upperEntity2Id ? data.upperEntity2Id : "") +
        (suffix2Url ?? ""),
      "POST",
      data.dto,
      decoder,
    );
};

export const restCreationMultipartHandler = <
  U,
  PrimaryType extends t.Props = t.Props,
  DecoderType extends
    DecoderTypeComputer<PrimaryType> = DecoderTypeComputer<PrimaryType>,
>(
  endpointUrl: string,
  suffixUrl?: string,
  decoderType?: DecoderType,
): ((data: { dto: FormData }) => Promise<ParsedResponse<U>>) => {
  return (data: { upperEntityId?: string; dto: FormData }) =>
    makeRestMultipartCall<U>(
      endpointUrl + (suffixUrl ?? ""),
      "POST",
      data.dto,
      decoderType,
    );
};

export const restListMultipartHandler = <
  U,
  PrimaryType extends t.Mixed = t.Mixed,
  DecoderType extends
    DecoderTypeComputer<PrimaryType> = DecoderTypeComputer<PrimaryType>,
>(
  endpointUrl: string,
  suffixUrl?: string,
  decoder?: DecoderType,
): ((data: { dto: FormData }) => Promise<ParsedResponse<U[]>>) => {
  return ({ dto }: { dto: FormData }) => {
    return makeRestMultipartCall<U[], PrimaryType, DecoderType>(
      `${endpointUrl}${suffixUrl ?? ""}`,
      "POST",
      dto,
      decoder,
    );
  };
};

export const restDeletionHandler = (
  endpointUrl: string,
  suffixUrl?: string,
): ((data: {
  upperEntityId?: string;
  id: string;
}) => Promise<ParsedResponse<void>>) => {
  return (data: { upperEntityId?: string; id: string }) =>
    makeRestCall(
      endpointUrl + (data.upperEntityId ?? "") + (suffixUrl ?? "") + data.id,
      "DELETE",
    );
};

export const restUpdateHandler = <
  T,
  U,
  PrimaryType extends t.Props | t.Mixed = t.Props,
  DecoderType extends
    DecoderTypeComputer<PrimaryType> = DecoderTypeComputer<PrimaryType>,
>(
  endpointUrl: string,
  suffixUrl?: string,
  decoder?: DecoderType,
): ((data: {
  upperEntityId?: string;
  id: string;
  dto: T;
}) => Promise<ParsedResponse<U>>) => {
  return (data: { upperEntityId?: string; id: string; dto: T }) =>
    makeRestCall<T, U, PrimaryType, DecoderType>(
      endpointUrl +
        (data.upperEntityId ?? data.id) +
        (suffixUrl ?? "") +
        (data.upperEntityId ? data.id : ""),
      "PUT",
      data.dto,
      decoder,
    );
};

export const restPatchHandler = <
  T,
  U,
  PrimaryType extends t.Props | t.Mixed = t.Props,
  DecoderType extends
    DecoderTypeComputer<PrimaryType> = DecoderTypeComputer<PrimaryType>,
>(
  endpointUrl: string,
  suffixUrl?: string,
  decoder?: DecoderType,
): ((data: {
  upperEntityId?: string;
  id: string;
  dto: T;
}) => Promise<ParsedResponse<U>>) => {
  return (data: { upperEntityId?: string; id: string; dto: T }) =>
    makeRestCall<T, U, PrimaryType, DecoderType>(
      endpointUrl +
        (data.upperEntityId ?? data.id) +
        (suffixUrl ?? "") +
        (data.upperEntityId ? data.id : ""),
      "PATCH",
      data.dto,
      decoder,
    );
};

export const restPostHandler = <
  T = undefined,
  U = undefined,
  PrimaryType extends t.Props | t.Mixed = t.Props,
  DecoderType extends
    DecoderTypeComputer<PrimaryType> = DecoderTypeComputer<PrimaryType>,
>(
  endpointUrl: string,
  suffixUrl?: string,
  decoder?: DecoderType,
): ((data: {
  upperEntityId?: string;
  dto?: T;
}) => Promise<ParsedResponse<U>>) => {
  return (data: { upperEntityId?: string; dto?: T }) =>
    makeRestCall<T, U, PrimaryType, DecoderType>(
      endpointUrl + (data.upperEntityId ?? "") + (suffixUrl ?? ""),
      "POST",
      data.dto,
      decoder,
    );
};
