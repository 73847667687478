import { computePathWithQueries, Query } from "@utils/helpers/query-helper";
import {
  CALLBACK_URL_PARAM,
  EMAIL_URL_PARAM,
  REGISTRATION_PARAM,
} from "@utils/Constant";

enum RoutePrefixes {
  ACCOUNT = "/account",
  E2E = "/e2e",
}

export const ROUTES = {
  public: {
    components: {
      generate: (): string => "/components",
    },
  },
  connected: {
    home: {
      generate: (): string => "/",
    },
    licenses: {
      generate: (): string => "/licenses",
    },
    account: {
      infos: {
        generate: (): string => `${RoutePrefixes.ACCOUNT}/me`,
      },
      updatePassword: {
        generate: (): string => `${RoutePrefixes.ACCOUNT}/me/update-password`,
      },
      expiredPassword: {
        generate: (): string => "/expired-password",
      },
    },
  },
  auth: {
    login: {
      generate: (options?: {
        callback?: string;
        registration?: string;
        email?: string;
      }): string => {
        const queries: Query[] = [];

        if (options?.callback !== undefined) {
          queries.push({ name: CALLBACK_URL_PARAM, value: options.callback });
        }

        if (options?.registration !== undefined) {
          queries.push({
            name: REGISTRATION_PARAM,
            value: options.registration,
          });
        }

        if (options?.email !== undefined) {
          queries.push({
            name: EMAIL_URL_PARAM,
            value: options.email,
          });
        }

        return computePathWithQueries("/login", queries);
      },
    },
    forgotPassword: {
      generate: (): string => "/forgot-password",
    },
    initPassword: {
      generate: (): string => "/init-password",
    },
    recoverPassword: {
      generate: (): string => "/recover-password",
    },
    registration: {
      generate: (): string => "/registration",
    },
    registrationConfirmation: {
      generate: (): string => "/registration/confirm",
    },
    uLink: {
      generate: (): string => "/u-link",
    },
    unauthorized: {
      generate: (): string => "/unauthorized",
    },
  },
  cookie: {
    generate: (): string => "/cookies",
  },
  maintenance: {
    generate: (): string => "/maintenance",
  },
  e2e: {
    users: {
      generate: (): string => `${RoutePrefixes.E2E}/users`,
    },
  },
  error404: {
    generate: (): string => "/404",
  },
  documentView: {
    generate: (transactionId: string, documentId: string): string =>
      `/document-view/${transactionId}/${documentId}`,
  },
};
