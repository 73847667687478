import { FunctionComponent, JSX, useEffect } from "react";
import { CARD_FLIP_BACK_DURATION } from "@utils/Constant";
import BasicCard from "@components/core/cards/BasicCard";

interface FlipCardProps {
  flipped?: boolean;
  onFlip: (flipped: boolean) => void;
  autoFlipBack?: boolean;
  flipOnClick?: boolean;
  flipBackDelay?: number; //Temps d'attente avant retour sur la face verso en ms
  frontSide: JSX.Element;
  frontSideVariant: string;
  backSide: JSX.Element;
  backSideVariant: string;
  className?: string;
  cardClassName?: string;
}

const FlipCard: FunctionComponent<FlipCardProps> = ({
  className = "",
  flipped = false,
  onFlip,
  autoFlipBack = true,
  flipOnClick = true,
  flipBackDelay = CARD_FLIP_BACK_DURATION,
  frontSide,
  frontSideVariant,
  backSide,
  backSideVariant,
  cardClassName = "",
}) => {
  useEffect(() => {
    if (flipped && autoFlipBack) {
      setTimeout(() => {
        onFlip(false);
      }, flipBackDelay);
    }
  }, [autoFlipBack, flipBackDelay, flipped, onFlip]);

  return (
    <div className={`app-card-flip ${flipped ? "flipped" : ""} ${className}`}>
      <BasicCard
        className={`front-content ${cardClassName} card-${frontSideVariant} ${flipOnClick ? "cursor-pointer" : ""}`}
        onClick={() => {
          if (flipOnClick) onFlip(true);
        }}
      >
        <>{frontSide}</>
      </BasicCard>
      <BasicCard
        className={`back-content ${cardClassName} card-${backSideVariant} ${flipOnClick ? "cursor-pointer" : ""}`}
        onClick={() => {
          if (flipOnClick) onFlip(false);
        }}
      >
        <>{backSide}</>
      </BasicCard>
    </div>
  );
};

export default FlipCard;
